import { useEffect as UseEffect } from 'react';
import useAxiosPrivate from '../auth_services/useAxiosPrivate';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes.js';
import SaleRoutes from './SaleRoutes.js';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // Check login
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    UseEffect(() => {
        checkLogin();
    }, []);
    const checkLogin = async () => {
        try {
            await axiosPrivate.get('authtoken');
            if (location.pathname === '/' || location.pathname === '/login') {
                navigate("/profile");
            }
        } catch (error) {
            navigate("/login");
        }
    }
    //**** Check login */

    return useRoutes([AdminRoutes, SaleRoutes, LoginRoutes]);
}

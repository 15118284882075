import axios from "../api/axios";
import useAxiosPrivate from "./useAxiosPrivate";
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const useAuth = {
    // Salt code for password
    Salt() {
        // Run one time for gen salt
        // const salt = bcrypt.genSaltSync(10);
        //  =>  $2a$10$1toXdMroaNgF19zXuwIwYu
        const Salt = '$2a$10$1toXdMroaNgF19zXuwIwYu';
        return Salt;
    },

    // Login
    async Login(req) {
        const response = await axios
            .post('/login', req, { withCredentials: true });
        return response;
    },

    // Logout
    async Logout(req) {
        localStorage.removeItem("token");
        const response = await axios
            .post('/logout', '', { withCredentials: true });
        return response;
    },

    // SignUp
    async Signup(req) {
        const response = await axios
            .post('/signup', req);
        return response;
    },

    // Changepassword
    async Changepassword(req) {
        const response = await axios
            .post('/changepassword', req);
        return response;
    },

    // Set token to local storage
    SetToken(token) {
        localStorage.removeItem("token");
        localStorage.setItem("token", token);
    },

    // Get token from local storage
    GetToken() {
        return localStorage.getItem("token");
    },

    // Get USER_ID
    userId() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["user_id"];
    },

    // Get MID
    mid() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["mid"];
    },

    // Get branch_id
    branchId() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["branch_id"];
    },

    // Get Role_id
    roleId() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["role_id"];
    },

    // Get USER_ID
    userName() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["username"];
    },

    // Get Name
    Name() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["first_name"] + ' ' + token["last_name"];
    },

    // Get Name
    userImage() {
        const token = jwt_decode(localStorage.getItem("token"));
        return token["profile_image"];
    },

    async AuthToken() {
        const axiosPrivate = useAxiosPrivate();
        const navigate = useNavigate();

        try {

            const res = await axiosPrivate.get('authtoken');
            return res;

        } catch (error) {

            navigate("/login");
        }
    }
}



export default useAuth;
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../../../auth_services/useAuth';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Chip,
    Badge,
    Tooltip
} from '@mui/material';
import {
    ContactMail as ContactMailIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon,
    Policy,
    Sell,
    ViewModule,
} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import billIcon from '../../../picture/menu/bill.png';
import branchIcon from '../../../picture/menu/branch.png';
import profileIcon from '../../../picture/menu/profile.png';
import userIcon from '../../../picture/menu/user.png';
import unitIcon from '../../../picture/menu/unit.png';
import promotionIcon from '../../../picture/menu/promotion.png';
import rateIcon from '../../../picture/menu/rate.png';
import taxIcon from '../../../picture/menu/tax.png';
import dashboardIcon from '../../../picture/menu/dashboad.png';
import productIcon from '../../../picture/menu/product.png';
import customerIcon from '../../../picture/menu/customer.png';
import productGroupIcon from '../../../picture/menu/product_group.png';
import prdOutofStockIcon from '../../../picture/menu/prd_outof_stock.png';
import prdExpiredIcon from '../../../picture/menu/prd_expired.png';
import sale_screen_cust from '../../../picture/menu/sale_screen_cust.png';
import sale_screen_admin from '../../../picture/menu/sale_screen_admin.png';
import stockIcon from '../../../picture/menu/stock.png';

const ListItemStyle = {
    color: 'text.leftmenu',
    '&.MuiListItem-root': {
        padding: '5px 0px 0px 15px',
    },
    '&:hover': {
        //fontWeight: 'bold',
        fontWeight: '900',
        //borderRadius: '5px',
        color: 'menu.selected',
        borderColor: 'menu.selected',
        borderStyle: 'solid',
        borderWidth: '0px 0px 0px 5px',
        borderRadius: '5px',
        fontWeight: '700',
        paddingLeft: '10px',
    },
    '&.css-10hburv-MuiTypography-root:hover': {
        fontWeight: '700'
    },
    /**
     * ListItem selected
     */
    '&.Mui-selected': {
        borderColor: 'menu.selected',
        borderStyle: 'solid',
        borderWidth: '0px 0px 0px 5px',
        //color: 'menu.selected',
        borderRadius: '5px',
        fontWeight: '700',
        paddingLeft: '10px',
    },
    '&.Mui-selected:hover': {
        borderColor: 'menu.selected',
        borderStyle: 'solid',
        borderWidth: '0px 0px 0px 5px',
        //color: 'menu.selected',
        borderRadius: '5px',
        fontWeight: '700',
        paddingLeft: '10px',
    },
    '.css-p15rgm-MuiTypography-root': {
        fontWeight: 'none'
    },

    /**
     * ListItemText selected font bold
     */
    '&.Mui-selected .css-10hburv-MuiTypography-root': {
        fontWeight: '700'
    },
    '&.MuiListItem-root:hover .css-10hburv-MuiTypography-root': {
        color: '#ffffff'
    },

    /**
     * Icon selected set color ffffff
     */
    '&.Mui-selected .css-i4bv87-MuiSvgIcon-root': {
        color: '#ffffff'
    }
};

const ListSubheaderStyle = {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '15px',
    paddingBottom: '8px',
    //color: 'menu.title',
    '&.MuiListSubheader-root': {
        lineHeight: '10px',
        backgroundColor: 'background.leftmenu'
    }
};

const ListItemIconStyle = {
    minWidth: '30px'
    //color: 'text.leftmenu',
};

const IconSize = {
    fontSize: '20px',
};

const Leftmenu = (props) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [menuActive, setMenuActive] = useState('');

    useEffect(() => {
        if (props.menuActive != undefined) {
            //console.log('True');
            setMenuActive(props.menuActive);
        } else {
            //console.log('false');
        }
    }, [props]);

    const setMaxVal = (val) => {
        if (val >= 1000) {
            return '999+';
        } else {
            return val
        }
    }

    /**
     * Handle sale customer screen
     */
    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    const [smallLeftMenu, setSmallLeftMenu] = useState(false);
    const onStorageUpdate = (e) => {
        const { key, newValue } = e;

        // Order item
        if (key === "smallLeftMenu") {
            setSmallLeftMenu(newValue);
        }
    };


    // Sale
    const sale = [
        {
            'link_name': 'ໜ້າຂາຍ POS-ລູກຄ້າ', 'path': '/sale/customer/screen', 'icon': <img
                src={sale_screen_cust}
                alt=""
                loading="lazy"
                style={{ width: '19px' }}
            />
        },
        {
            'link_name': 'ໜ້າຂາຍ POS-ຜູ້ຂາຍ', 'path': '/sale', 'icon': <img
                src={sale_screen_admin}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        /*{
            'link_name': 'ໜ້າຂາຍ Admin', 'path': '/sale/admin', 'icon': <img
                src={sale_screen_admin}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },*/
    ];

    // Report
    const report = [
        {
            'link_name': 'ລາຍງານລວມ', 'path': '/daily/report', 'icon': <img
                src={dashboardIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        { 'role': useAuth.roleId(), 'link_name': 'ລາຍງານການຂາຍ', 'path': '/sale/report', 'icon': <Sell sx={IconSize} /> },
        { 'role': useAuth.roleId(), 'link_name': 'ລາຍງານການຊື້', 'path': '/buy/report', 'icon': <Sell sx={IconSize} /> },
        //{ 'link_name': 'ສິນຄ້າໃນສາງ', 'path': '/product/stock/report', 'icon': <ViewModule sx={IconSize} /> },
        //{ 'link_name': 'ສິນຄ້າໄກ້ໝົດອາຍຸ', 'path': '/goods/expire', 'icon': <WatchLater  sx={IconSize}/> },
    ];

    // Stock
    const stock = [
        {
            'link_name': 'ສາງສິນຄ້າ', 'path': '/product/stock', 'icon': <img
                src={stockIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'link_name': 'ສິນຄ້າໄກ້ໝົດສາງ', 'path': '/product/outofstock', 'icon': <img
                src={prdOutofStockIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />, 'countNotify': setMaxVal(props.prdOutofStockCount), 'chip': <Chip label={setMaxVal(props.prdOutofStockCount)} size='small' color='error' sx={{ minWidth: '20px' }} />
        },
        {
            'link_name': 'ສິນຄ້າໄກ້ໝົດອາຍຸ', 'path': '/product/stock/expire', 'icon': <img
                src={prdExpiredIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />, 'countNotify': setMaxVal(props.prdExpireSoonCount), 'chip': <Chip label={setMaxVal(props.prdExpireSoonCount)} size='small' color='error' />
        }
    ];

    // Product
    const product = [
        {
            'link_name': 'ຂໍ້ມູນສິນຄ້າ', 'path': '/product', 'icon': <img
                src={productIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'link_name': 'ຂໍ້ມູນກຸ່ມສິນຄ້າ', 'path': '/product/group', 'icon': <img
                src={productGroupIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'link_name': 'ຂໍ້ມູນຫົວໜ່ວຍ', 'path': '/unit', 'icon': <img
                src={unitIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
    ];

    // Product
    const customer = [
        {
            'link_name': 'ຂໍ້ມູນລູກຄ້າ', 'path': '/customer', 'icon': <img
                src={customerIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        }
    ];

    // General setting
    const GeneralSetting = [
        /*{
            'link_name': 'ໂປໂມຊັ່ນ', 'path': '/promotion', 'icon': <img
                src={promotionIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },*/
        {
            'link_name': 'ອັດຕາເເລກປ່ຽນ', 'path': '/exchange-rate', 'icon': <img
                src={rateIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'link_name': 'ອາກອນມູນຄ່າເພີ່ມ', 'path': '/vat', 'icon': <img
                src={taxIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'link_name': 'ຂໍ້ມູນໃບບິນ', 'path': '/receipt_info', 'icon': <img
                src={billIcon}
                alt=""
                loading="lazy"
                style={{ width: '18px' }}
            />
        },
        /*{
            'link_name': 'ຂໍ້ມູນສາຂາ', 'path': '/branch', 'icon': <img
                src={branchIcon}
                alt=""
                loading="lazy"
                style={{ width: '18px' }}
            />
        },*/
    ];

    // Contact group menu
    const Contact = [
        { 'link_name': 'ຂໍ້ມູນການຕິດຕໍ່', 'path': '/contact', 'icon': <ContactMailIcon sx={IconSize} /> },
        { 'link_name': 'ຂໍ້ມູນກ່ຽວກັບພວກເຮົາ', 'path': '/about', 'icon': <SupervisedUserCircleIcon sx={IconSize} /> },
        { 'link_name': 'ຂໍ້ກໍານົດ ເເລະ ນະໂຍບາຍ', 'path': '/termspolicy', 'icon': <Policy sx={IconSize} /> }
    ];

    // User group menu
    const UserGroupMenu = [
        {
            'role': 'all', 'link_name': 'ຂໍ້ມູນສ່ວນຕົວ', 'lng': 'profile', 'path': '/profile', 'icon': <img
                src={profileIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        {
            'role': useAuth.roleId(), 'link_name': 'ຂໍ້ມູນຜູ້ໃຊ້ລະບົບ', 'lng': 'user', 'path': '/user', 'icon': <img
                src={userIcon}
                alt=""
                loading="lazy"
                style={{ width: '20px' }}
            />
        },
        //{ 'link_name': 'ຂໍ້ມູນສິດຜູ້ໃຊ້', 'lng': 'user role', 'path': '/userrole', 'icon': <UserPermissionIcon /> }
    ];

    return (
        <>
            {/*** Sale */}
            <List subheader={(() => {
                if (props.menuStatus != 'small') {
                    return (<ListSubheader sx={ListSubheaderStyle}>ຂາຍເຄື່ອງ</ListSubheader>)
                }
            })()}
            >
                {
                    sale.map((text, index) => (
                        <ListItem
                            sx={ListItemStyle}
                            key={text.link_name}
                            component={NavLink}
                            to={text.path}
                            target={(text.path == '/sale/admin') ? '' : '_blank'}
                            selected={location.pathname === text.path || menuActive === text.path}
                        >
                            <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                            </Tooltip>
                            <ListItemText>
                                {(() => {
                                    if (props.menuStatus != 'small') {
                                        return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                    } else {
                                        return (<>&nbsp;</>)
                                    }
                                })()}
                            </ListItemText>
                        </ListItem>
                    ))
                }
            </List >

            {/*** Report */}
            {useAuth.roleId() == '1' &&
                <List subheader={(() => {
                    if (props.menuStatus != 'small') {
                        return (<ListSubheader sx={ListSubheaderStyle}>ລາຍງານ</ListSubheader>)
                    }
                })()}
                >
                    {
                        report.map((text, index) => (
                            <ListItem
                                sx={ListItemStyle}
                                key={text.link_name}
                                component={NavLink}
                                to={text.path}
                                selected={location.pathname === text.path || menuActive === text.path}
                            >
                                <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                    <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    {(() => {
                                        if (props.menuStatus != 'small') {
                                            return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                        } else {
                                            return (<>&nbsp;</>)
                                        }
                                    })()}

                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List >
            }

            {/*** Stock */}
            {useAuth.roleId() == '1' &&
                <List subheader={(() => {
                    if (props.menuStatus != 'small') {
                        return (<ListSubheader sx={ListSubheaderStyle}>ສາງສິນຄ້າ</ListSubheader>)
                    }
                })()}
                >
                    {
                        stock.map((text, index) => (
                            <ListItem
                                sx={ListItemStyle}
                                key={text.link_name}
                                component={NavLink}
                                to={text.path}
                                selected={location.pathname === text.path || menuActive === text.path}
                            >
                                {(() => {
                                    if (props.menuStatus != 'small') {
                                        return (
                                            <>
                                                <ListItemIcon sx={ListItemIconStyle}>
                                                    {text.icon}
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <label className='font14 cursor-pointer'>{text.link_name}</label>
                                                </ListItemText>
                                                <ListItemIcon sx={ListItemIconStyle}>
                                                    {text.countNotify > 0 ? (
                                                        text.chip
                                                    ) : (
                                                        ''
                                                    )}
                                                </ListItemIcon>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                                    <ListItemIcon sx={ListItemIconStyle}>
                                                        <Badge badgeContent={text.countNotify} color='error'>
                                                            {text.icon}
                                                        </Badge>
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText>&nbsp;</ListItemText>
                                            </>
                                        )
                                    }
                                })()}


                            </ListItem>
                        ))
                    }
                </List >
            }

            {/*** Product */}
            {useAuth.roleId() == '1' &&
                <List subheader={(() => {
                    if (props.menuStatus != 'small') {
                        return (<ListSubheader sx={ListSubheaderStyle}>ຂໍ້ມູນສິນຄ້າ</ListSubheader>)
                    }
                })()}
                >
                    {
                        product.map((text, index) => (
                            <ListItem
                                sx={ListItemStyle}
                                key={text.link_name}
                                component={NavLink}
                                to={text.path}
                                selected={location.pathname === text.path || menuActive === text.path}
                            >
                                <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                    <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    {(() => {
                                        if (props.menuStatus != 'small') {
                                            return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                        } else {
                                            return (<>&nbsp;</>)
                                        }
                                    })()}
                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List >
            }

            {/*** Customer*/}
            {/*
            <List subheader={(() => {
                if (props.menuStatus != 'small') {
                    return (<ListSubheader sx={ListSubheaderStyle}>ຂໍ້ມູນລູກຄ້າ</ListSubheader>)
                }
            })()}
            >
                {
                    customer.map((text, index) => (
                        <ListItem
                            sx={ListItemStyle}
                            key={text.link_name}
                            component={NavLink}
                            to={text.path}
                            selected={location.pathname === text.path || menuActive === text.path}
                        >
                            <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                            </Tooltip>
                            <ListItemText>
                                {(() => {
                                    if (props.menuStatus != 'small') {
                                        return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                    } else {
                                        return (<>&nbsp;</>)
                                    }
                                })()}
                            </ListItemText>
                        </ListItem>
                    ))
                }
            </List >
            */}

            {/*** General setting*/}
            {useAuth.roleId() == '1' &&
                <List subheader={(() => {
                    if (props.menuStatus != 'small') {
                        return (<ListSubheader sx={ListSubheaderStyle}>ຕັ້ງຄ່າທົ່ວໄປ</ListSubheader>)
                    }
                })()}
                >
                    {
                        GeneralSetting.map((text, index) => (
                            <ListItem
                                sx={ListItemStyle}
                                key={text.link_name}
                                component={NavLink}
                                to={text.path}
                                selected={location.pathname === text.path || menuActive === text.path}
                            >
                                <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                    <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    {(() => {
                                        if (props.menuStatus != 'small') {
                                            return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                        } else {
                                            return (<>&nbsp;</>)
                                        }
                                    })()}

                                </ListItemText>
                            </ListItem>
                        ))
                    }
                </List >
            }

            {/*** User*/}

            <List subheader={(() => {
                if (props.menuStatus != 'small') {
                    return (<ListSubheader sx={ListSubheaderStyle}>ຂໍ້ມູນຜູ້ໃຊ້ລະບົບ</ListSubheader>)
                }
            })()}
            >
                {
                    UserGroupMenu.map((text, index) => (
                        (text.role == '1' || text.role == 'all') &&
                        <ListItem
                            sx={ListItemStyle}
                            key={text.link_name}
                            component={NavLink}
                            to={text.path}
                            selected={location.pathname === text.path || props.menuActive === text.path}
                        >
                            <Tooltip title={(props.menuStatus != 'small') ? '' : text.link_name} placement='right' arrow>
                                <ListItemIcon sx={ListItemIconStyle}>{text.icon}</ListItemIcon>
                            </Tooltip>
                            <ListItemText>
                                {(() => {
                                    if (props.menuStatus != 'small') {
                                        return (<label className='font14 cursor-pointer'>{text.link_name}</label>)
                                    } else {
                                        return (<>&nbsp;</>)
                                    }
                                })()}

                            </ListItemText>
                        </ListItem>

                    ))
                }
            </List >


        </>
    )

}

export default Leftmenu
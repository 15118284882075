import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Leftmenu from './Leftmenu';

//type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Leftmenu
                prdOutofStockCount={props.prdOutofStockCount}
                prdExpireSoonCount={props.prdExpireSoonCount}
            />
        </Box>
    );

    return (
        <div>
            {
                //(['left', 'right', 'top', 'bottom']).map((anchor) => ( // original

                (['left']).map((anchor) => (
                    <React.Fragment key={anchor}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2, marginLeft: '5px' }}
                            onClick={toggleDrawer(anchor, true)}
                        >
                            <Tooltip title="more">
                                <MenuIcon />
                            </Tooltip>

                        </IconButton>

                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    backgroundColor: 'background.leftmenu',
                                    backgroundImage: 'none'
                                }
                            }}
                        >
                            {list(anchor)}

                        </Drawer>
                    </React.Fragment>
                ))
            }
        </div >
    );
}

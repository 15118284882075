import axios from "axios";
//const base_url = 'http://10.0.29.246:5000';
//const base_url = 'http://192.168.100.40:4500';
//const base_url = 'http://127.0.0.1:4500';
//const base_url = 'http://128.199.139.198:4500';
//const base_url = 'tayai.la:3000';

const base_url = 'http://pos.tayai.la:4500';

export default axios.create({
    baseURL: base_url
});

export const axiosPrivate = axios.create({
    baseURL: base_url,
    //headers: { 'Content-Type': 'application/json' },
    //headers: { 'Content-Type': 'multipart/form-data' }
});


export const api_ip = () => {
    return base_url
};
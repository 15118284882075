import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../auth_services/useAxiosPrivate';
import { CircularProgress, Box, IconButton, Avatar, Tooltip } from '@mui/material';
import { FirstPage, LastPage } from '@mui/icons-material';
import Leftmenu from './menu/Leftmenu';
import Appbar from './Appbar';
import Notification from '../../pages/Notification';
import '../../css/table_style.css';

const MainLayout = () => {
    // setOutletContext for set attribute value from route children element
    const [outletContext, setOutletContext] = useState("");

    // Check login for loading page
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();

    const [menuSmall, setMenuSmall] = useState('large');

    // Notification
    const [prdOutofStockCount, setPrdOutofStockCount] = useState('');
    const [prdExpireSoonCount, setPrdExpireSoonCount] = useState('');

    useEffect(() => {
        checkLogin();

        const leftMenuResize = localStorage.getItem("leftMenuResize");

        if (leftMenuResize != null) {
            setMenuSmall(leftMenuResize);
        }
    }, [])

    const checkLogin = async () => {
        try {
            await axiosPrivate.get('authtoken');
            setLoading(false);
        } catch (error) {
            console.log('error', error);
        }
    }



    // Loading
    if (loading) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress className='tyLoadingPosition' />
            </Box>
        )
    }

    // Main layout show
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>

                <Appbar
                    pageTitle={outletContext.pageTitle}
                    prdOutofStockCount={prdOutofStockCount}
                    prdExpireSoonCount={prdExpireSoonCount}
                />

                <Box sx={{
                    display: 'flex',
                    width: '100%'
                }}>
                    <Box position="fixed">
                        <Box
                            component="nav"
                            sx={{
                                padding: "0px 5px 5px 5px",
                                marginTop: '66px',
                                width: (menuSmall == 'large') ? '230px' : '70px',
                                height: "calc(100vh - 120px)",
                                display: { xs: 'none', md: 'block' },
                                overflowY: 'auto',
                                backgroundColor: 'background.leftmenu',
                                borderRight: '1px solid',
                                borderRightColor: 'boderColor.default',
                                zIndex: '1100'
                            }}
                        >

                            <Leftmenu
                                menuActive={outletContext.menuActive}
                                menuStatus={menuSmall}
                                prdOutofStockCount={prdOutofStockCount}
                                prdExpireSoonCount={prdExpireSoonCount}
                            />

                        </Box>

                        <Box sx={{
                            width: (menuSmall == 'large') ? '230px' : '70px',
                            padding: (menuSmall == 'large') ? '13px 0px 13px 90px' : '13px 0px 13px 17px',
                            marginTop: '0px',
                            backgroundColor: 'background.leftmenu',
                            borderStyle: 'solid',
                            borderWidth: '1px 1px 0px 0px',
                            borderColor: 'boderColor.default',
                        }} >
                            {/*<Tooltip title={(menuSmall == 'large') ? 'ຫຍໍ້ເມນູ' : 'ຂະຫຍາຍເມນູ'}>*/}
                            <Avatar
                                sx={{ width: 26, height: 26, bgcolor: 'text.primary' }}
                                className='cursor-pointer'
                                onClick={() => {
                                    if (menuSmall == 'large') {
                                        setMenuSmall('small');
                                        // set leftMenuResize
                                        localStorage.setItem("leftMenuResize", 'small');
                                    } else {
                                        setMenuSmall('large');
                                        // set leftMenuResize
                                        localStorage.setItem("leftMenuResize", 'large');
                                    }
                                }}
                            >
                                {(() => {
                                    if ((menuSmall == 'large')) {
                                        return (<FirstPage sx={{ fontSize: '20px' }} />)
                                    } else {
                                        return (<LastPage sx={{ fontSize: '20px' }} />)
                                    }
                                })()}

                            </Avatar>
                            {/*</Tooltip>*/}
                        </Box>
                    </Box>

                    <Box sx={{ minWidth: (menuSmall == 'large') ? '210px' : '40px', display: { xs: 'none', md: 'block' } }} />
                    <Box
                        component="main"
                        position="static"
                        sx={{
                            height: "calc(100vh - 10%)",
                            flexGrow: 1,
                            overflowY: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '70px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            //width: '600px',
                            padding: "30px 30px 0px 50px",
                            '@media screen and (max-width: 900px)': {
                                padding: "0px 15px 0px 15px",
                            },
                        }}
                    >

                        <Outlet
                            context={{
                                setOutletContext,       // Set val
                                setPrdExpireSoonCount,  // set val
                                setPrdOutofStockCount,  // set val
                                prdOutofStockCountSoon: prdOutofStockCount,     // get val
                                prdExpireSoonCount      // get val
                            }}
                        />

                    </Box>
                </Box >
            </Box >

            <Notification
                setPrdExpireSoonCount={setPrdExpireSoonCount}
                setPrdOutofStockCount={setPrdOutofStockCount}
            />

        </>
    )

}

export default MainLayout
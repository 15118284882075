import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../auth_services/useAxiosPrivate';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Badge,
    MenuItem,
    Menu,
    useScrollTrigger,
    Tooltip,
    Avatar,
    TextField,
    InputAdornment,
    Divider
} from '@mui/material';
import {
    Logout,
    Notifications as NotificationsIcon,
    MoreVert as MoreIcon,
    Email as MailIcon,
    AccountCircle,
    Brightness6,
    DarkMode,
    Search,
} from '@mui/icons-material';
import { api_ip } from '../../api/axios';
//import useAuth from '../../auth_services/useAuth';
// Drawer for left menu
import Drawer from './menu/Drawer';
import useAuth from '../../auth_services/useAuth';
import { ThemeContext } from "../../Theme";

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function PrimaryAppBar(props) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const { theme, toggleTheme, modeTitle } = useContext(ThemeContext);

    const axiosPrivate = useAxiosPrivate();

    // Image path
    let imgPath = api_ip() + "/profile/image/";

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        getReceiptInfo();

    }, []);

    const [receiptInfo, setReceiptInfo] = useState([]);
    // Receipt info
    const getReceiptInfo = async () => {
        try {
            const mid = useAuth.mid();
            const response = await axiosPrivate.get(`receipt_info/${mid}`);
            setReceiptInfo(response.data[0]);

        } catch (error) {
            console.log('error----->>>', error);
            //navigate("/login");
        }
    }


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = (url) => {
        setAnchorEl(null);
        navigate(url);
        handleMobileMenuClose();

        if (url === '/logout') {
            logout();
        }
    };

    const logout = async () => {
        try {
            useAuth.Logout();
            navigate("/login");
        } catch (error) {
            console.log(error);
        }
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.menu',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                    bgcolor: 'background.menu',
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => { handleMenuClose('/profile') }}><AccountCircle /> &nbsp; ຂໍ້ມູນສ່ວນຕົວ</MenuItem>
            <MenuItem onClick={() => { handleMenuClose('/logout') }}><Logout /> &nbsp; ອອກຈາກລະບົບ</MenuItem>
        </Menu >
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/*
            <MenuItem
                onClick={() => {
                    toggleTheme();
                }}
            >
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    {
                        theme === 'dark' ? (

                            <Brightness6 />

                        ) : (

                            <DarkMode />

                        )

                    }
                </IconButton>
                <p>{modeTitle()}</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>ຂໍ້ມູນສ່ວນຕົວ</p>
            </MenuItem>
            */}


            <MenuItem
                onClick={() => {
                    toggleTheme();
                }}
            >
                {
                    theme === 'dark' ? (

                        <Brightness6 />

                    ) : (

                        <DarkMode />

                    )

                }
                <p> &nbsp; {modeTitle()}</p>
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose('/profile') }}>
                <Avatar
                    alt="Remy Sharp"
                    src={imgPath + useAuth.userImage()}
                    sx={{ marginTop: '0px', width: 30, height: 30 }}
                    onClick={() => { handleMenuClose('/profile') }}
                    className='cursor-pointer'
                />
                &nbsp; ຂໍ້ມູນສ່ວນຕົວ
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { handleMenuClose('/logout') }}><Logout /> &nbsp; ອອກຈາກລະບົບ</MenuItem>
        </Menu>
    );

    return (
        <Box sx={{
            flexGrow: 1,
            boxShadow: 1
        }}>
            {/*<ElevationScroll {...props}>*/}
            <AppBar
                elevation={0}
                sx={{
                    '&.MuiAppBar-root': {
                        backgroundColor: 'background.leftmenu',
                        color: 'text.primary',
                        //color: '#ffffff'
                    },
                    '.css-1hnxwkd-MuiPaper-root-MuiAppBar-root': {
                        backgroundImage: ''
                    },
                    '.css-hyum1k-MuiToolbar-root': {
                        '@media screen and (max-width: 900px)': {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                    },
                    borderColor: 'boderColor.line',
                    borderStyle: 'solid',
                    borderWidth: '0px 0px 1px 0px'
                }}>
                <Toolbar
                //variant="dense"
                >
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Drawer
                            prdOutofStockCount={props.prdOutofStockCount}
                            prdExpireSoonCount={props.prdExpireSoonCount}
                        />
                    </Box>
                    <Box
                        sx={{
                            height: '80px',
                            //width: '230px',
                            marginLeft: '-24px',
                            marginTop: '-15px',
                            backgroundColor: 'background.leftmenu',
                            //borderRight: '1px solid',
                            //borderRightColor: 'boderColor.default',
                            padding: '28px 0px 0px 20px',
                            //display: { xs: 'none', md: 'flex' }
                        }}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                fontSize: '25px',
                                fontWeight: 'bold',
                                //color: 'text.fixed',
                            }}
                        >
                            {receiptInfo.merchant_name}
                        </Typography>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'none' }, paddingLeft: '20px' }}>
                        <TextField
                            id="outlined-basic"
                            label=""
                            size='small'
                            placeholder='ຄົ້ນຫາ'
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                            }}
                        />
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/*
                        <Tooltip title='ຂໍ້ຄວາມ'>
                            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                                <Badge badgeContent={4} color="error">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='ເເຈ້ງເຕືອນ'>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        */}
                        <Tooltip title={modeTitle()}>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                onClick={() => {
                                    toggleTheme();
                                }}
                            >
                                {
                                    theme === 'dark' ? (

                                        <Brightness6 />

                                    ) : (

                                        <DarkMode />

                                    )

                                }
                            </IconButton>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title='ຂໍ້ມູນສ່ວນຕົວ'>
                            <Avatar
                                alt="Remy Sharp"
                                src={imgPath + useAuth.userImage()}
                                sx={{ marginTop: '5px', width: 40, height: 40 }}
                                onClick={() => { handleMenuClose('/profile') }}
                                className='cursor-pointer'
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title='ຊື່ຜູ້ໃຊ້ງານ'>
                            <Box
                                sx={{ marginTop: '5px', fontSize: '12px', display: { xs: 'none', md: 'block' } }}
                                onClick={() => { handleMenuClose('/profile') }}
                                className='cursor-pointer'
                            >
                                <Box style={{ fontWeight: 'bold' }}>{useAuth.Name()}</Box>
                                <Box>{useAuth.userName()}</Box>
                            </Box>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title='ອອກຈາກລະບົບ'>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                onClick={() => { handleMenuClose('/logout') }}
                            >
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    { /* for show more icon when windows resize to min*/}

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >

                            <MoreIcon />

                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            {/*</ElevationScroll>*/}
            {renderMobileMenu}
            {renderMenu}
        </Box >
    );
}
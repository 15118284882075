import { Outlet } from 'react-router-dom';
import { useState, useEffect as UseEffect } from 'react';
import useAxiosPrivate from '../../auth_services/useAxiosPrivate';
import { CircularProgress, Box } from '@mui/material';
import '../../css/table_style.css';

const SaleLayout = () => {
    // setOutletContext for set attribute value from route children element
    const [outletContext, setOutletContext] = useState("");

    // Check login for loading page
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    UseEffect(() => {
        checkLogin();
    }, [])
    const checkLogin = async () => {
        try {
            await axiosPrivate.get('authtoken');
            setLoading(false);
        } catch (error) {
            console.log('error', error);
        }
    }

    // Loading
    if (loading) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress className='tyLoadingPosition' />
            </Box>
        )
    }

    // Main layout show
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>

                {/*<Appbar pageTitle={outletContext.pageTitle} width={200} />*/}

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    border: '1 solid',
                    borderColor: 'red'
                }}>
                    <Box
                        component="main"
                        position="static"
                        sx={{
                            height: "calc(100vh - 10%)",
                            padding: "0px 6px 0px 6px",
                            flexGrow: 1,
                            overflowY: 'auto',
                            justifyContent: 'center',
                            //marginTop: '70px',
                            border: '1 solid',
                            borderColor: 'red'
                        }}
                    >
                        <Outlet context={{ setOutletContext }} />
                    </Box>
                </Box >
            </Box >
        </>
    )

}

export default SaleLayout
import React, { useState, useEffect } from 'react';
import useAuth from '../auth_services/useAuth';
import useAxiosPrivate from '../auth_services/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';

const Notification = (props) => {
    // Call navigate
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        prdOutofStock();
        prdStockExpire();
    });

    // Product outof stock soon
    const prdOutofStock = async () => {
        try {
            const mid = useAuth.mid();
            const response = await axiosPrivate.get(`product/outofstocksoon/count/${mid}`);
            props.setPrdOutofStockCount(response.data[0].prd_outof_stock);

        } catch (error) {
            navigate("/login");
        }
    }

    // Product stock expire soon
    const prdStockExpire = async () => {
        try {
            const mid = useAuth.mid();
            const response = await axiosPrivate.get(`product/stock/expirecount/${mid}`);
            props.setPrdExpireSoonCount(response.data[0].notify_expire);

        } catch (error) {
            navigate("/login");
        }
    }


    return (
        <></>
    )
}

export default Notification
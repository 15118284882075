import axios, { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                //console.log("req Token=>", useAuth.GetToken());
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${useAuth.GetToken()}`;
                }
                return config
            }, (error) => Promise.reject(error)

        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                //if (error.response.status === 401 || !originalRequest._retry) { // Not work
                if (error.response.status === 401) {
                    try {
                        originalRequest._retry = true;
                        const response = await axios.get('/refreshtoken', {
                            withCredentials: true,
                            credentials: 'include',
                        });

                        useAuth.SetToken(response.data.accessToken);

                        originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;

                        return axiosPrivate(originalRequest);

                    } catch (error) {
                        //console.log("use Refresh error", error);
                        //console.log("Please login");

                        navigate("/login");
                    }
                }

                return Promise.reject(error);
            }
        );


        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }

    }, [])

    return axiosPrivate;
}

export default useAxiosPrivate;
import { lazy } from 'react';

// project import
import Loadable from './Loadable';
import MinimalLayout from '../layout/LoginLayout';
//import useAxiosPrivate from '../auth_services/useAxiosPrivate';
//const axiosPrivate = useAxiosPrivate();

// render - login
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
//const Register = Loadable(lazy(() => import('../pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

//const res = axiosPrivate.get('authtoken');
//console.log(res);

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '*',
            element: <>Page Not Found</>
        }
    ]
};

export default LoginRoutes;
